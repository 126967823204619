
import { Options, Vue } from 'vue-class-component';
import { CustomerAuthClient, CustomerClient, LocalResourceContryClient } from '@/services/services';
import { LocalizationServices } from '@/services/LocalizationServices';
import { LoginServices } from '@/services/LoginServices';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({})
export default class Register extends Vue {

    updateModel: OM.Customer = new OM.Customer();
    registerModel: OM.LanguageCredential = new OM.LanguageCredential();

    gender: string = null;
    nationalities: OM.TextIdentifier[] = [];

    created() {
        this.registerModel.preferredCulture = LocalizationServices.GetLanguage();
        this.updateModel.createdOn = new Date().toISOString();	
        this.updateModel.modifiedOn = new Date().toISOString();	
        this.updateModel.preferredCulture = this.registerModel.preferredCulture;

        LocalResourceContryClient.getNationalities()
        .then(x => {
            x.forEach(element => {
                var textId = new OM.TextIdentifier();
                textId.identifier = element;
                textId.text = element;
                this.nationalities.push(textId);
            });
        })
    }

    get isDisable() {
        return !this.updateModel.jobTitle || !this.updateModel.studyTitle || !this.updateModel.birthDate ||
            !this.registerModel.username || !this.registerModel.password;
    }

    register() {
        if(this.gender == null)
            this.updateModel.isMale = null;
        else if(this.gender == "male")
            this.updateModel.isMale = true;
        else
            this.updateModel.isMale = false;

        CustomerAuthClient.register(this.registerModel)
        .then(x => {
            this.updateModel.identifier = x.identifier;
            LoginServices.LoginCallback(x)
            .then(y => {
                CustomerClient.save(this.updateModel)
                .then(x => {
                    this.$router.push("/exhibition/" +  store.state.exhibition.identifier); 
                })
            });
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_somethingWentWrongRetryLater", "Something went wrong, retry later."),
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
